import "videojs-youtube/dist/Youtube";
import React, { useState } from "react";
import PdfViewer from "../utils/pdfViewer";
import { useParams, useSearch } from "@tanstack/react-router";
import { useApi, useMount } from "../hooks";
import PlayerHeader from "../component/player/playerHeader";
import CourseContent from "../component/player/courseContent";
import Curriculum from "../component/player/curriculum";
import Loader from "../component/loader";
import CustomPlayer from "../component/player/CustomPlayer";

import {
  IAnnouncement,
  ICourse,
  ILesson,
  INote,
  IVideo,
} from "../interfaces/course";
import { TYPE } from "../interfaces/player.d";
import moment from "moment";
import FeedBackModal from "../utils/feedBackModal";
import { Card } from "antd";
import { useAuthStore } from "../store/auth";

export type TCourseContent = {
  timetable: any;
  course: ICourse;
  lessons: ILesson[];
  notes: INote[];
  details: { pdfs: any };
};

export type TQuizRes = {
  dpps: any[];
  quizzes: any[];
  testseries: any[];
  wmts: any[];
};

export type TFeedBack = {
  id: number;
  name: string;
  type: "classes";
};

const CoursePlayer = () => {
  const { courseId } = useParams({ from: "/courses/$courseId/play" });
  const { vvid, llid } = useSearch({ from: "/courses/$courseId/play" });
  // const navigate = useNavigate({from:'/courses/$courseId/play'})

  const [selectedVideo, setSelectedVideo] = useState<IVideo>();
  const [selectedLesson, setSelectedLesson] = useState<ILesson>();
  const [open, setOpen] = React.useState(true);
  const [videoType, setVideoType] = useState(TYPE.video);
  const [feedBack, setFeedBack] = useState<TFeedBack | null>(null);
  const url = new URL(String(window?.location));
  const [videos, pdfs, live]: IVideo[][] = [[], [], []];

  const {
    data: courseContent,
    load,
    isLoading,
  } = useApi<TCourseContent>(`/user/courses/${courseId}/lessons`);

  const { data: content, load: loadDetails } = useApi<TCourseContent>(
    `/user/courses/${courseId}`
  );

  const {
    data: announcement,
    load: loadAnnouncement,
    isLoading: loadingAnnouncement,
  } = useApi<IAnnouncement[]>();

  const {
    data: quiz,
    load: loadQuiz,
    isLoading: loadingQuiz,
  } = useApi<TQuizRes>();

  selectedLesson?.videos?.forEach((item) =>
    item.type === "pdf"
      ? pdfs.push(item)
      : item.is_live
      ? live.push(item)
      : videos.push(item)
  );

  const {
    data: dpt,
    load: loadDPT,
    isLoading: loadingDPT,
  } = useApi<any>(`/lessons/${llid}`);


  useMount(() => {
    if (!llid) return;
    loadDPT();
  }, [llid]);

  useMount(() => {
    load();
    loadDetails();
  });

  useMount(() => {
    if (!courseContent) return;
    const lesson =
      courseContent?.lessons.find((item) => item.id === llid) ||
      courseContent?.lessons[0];
    setSelectedLesson(lesson);
    const cId = courseContent.course.id;
    loadAnnouncement({ uri: `courses/${cId}/announcements` });
    loadQuiz({ uri: `/courses/${cId}/quizzes` });
  }, [courseContent]);

  const findVideo = () => {
    return (
      videos.find((v) => v.id === vvid) ||
      videos.find(
        (v) => v.published_at && !moment().isBefore(v.published_at)
      ) ||
      videos[0]
    );
  };

  useMount(() => {
    if (!selectedLesson) return;
    const video =
      videoType === TYPE.video
        ? findVideo()
        : pdfs.find((v) => v.id === vvid) || pdfs[0];

    setSelectedVideo(video);
    url.searchParams.set("llid", String(selectedLesson?.id));
    window.history.replaceState(null, "", url.toString());
  }, [selectedLesson, videoType]);

  useMount(() => {
    if (!selectedVideo?.id) return;
    url.searchParams.set("vvid", String(selectedVideo?.id));
    window.history.replaceState(null, "", url.toString());
  }, [selectedVideo]);

  return isLoading ? (
    <div style={{ marginTop: 20 }}>
      <Loader />
    </div>
  ) : (
    <>
      <div className="player-container">
        <FeedBackModal
          feedBack={feedBack}
          setFeedBack={(feedBack) => setFeedBack(feedBack)}
          isFeedBackSubmitted={false}
          course_name={courseContent?.course?.title!}
        />
        <PlayerHeader
          lId={llid || selectedLesson?.id}
          videoType={videoType}
          setVideoType={setVideoType}
          handleToggle={() => setOpen((open) => !open)}
          setLesson={setSelectedLesson}
          lesson={selectedLesson}
          course={courseContent}
          open={open}
        />

        <div className={`course-player-container hideScrollbar `}>
          <div
            className="video-content-container"
            // className="courseVideoScrollbar"
          >
            {/* <div className="player-container"> */}
            {videoType === TYPE.pdf ? (
              <div id="pdf-notes">
                <PdfViewer pdfUrl={selectedVideo?.video_url} height={"700px"} />
              </div>
            ) : (
              <>
                <CustomPlayer
                  isLive={false}
                  selectedVideo={selectedVideo}
                  // onEnd={() => setNext(true)}
                  onEnd={() => {}}
                />
              </>
            )}
            {/* </div> */}
            {selectedVideo?.name && (
              <Card
                style={{ width: "98%", margin: "auto", marginTop: "1%" }}
                size="small"
              >
                <h2 style={{ marginBottom: "0%", fontWeight: "600" }}>
                  {selectedVideo?.name}
                </h2>
              </Card>
            )}
            {quiz && (
              <CourseContent
                loading={loadingAnnouncement}
                courseDetail={courseContent?.course!}
                announcements={announcement!}
                lessons={courseContent?.lessons!}
                quizzesData={quiz}
                lessonId={selectedLesson?.id!}
                timeTable={content?.timetable}
                lecturePlan={content?.details?.pdfs}
                dpps={dpt?.dpps}
                subjective={dpt?.subjectives}
                videos={videos}
                wmts={dpt?.wmts}
              />
            )}
          </div>
          <div
            className={`content-sidebar ${open && `open vjs-sidebar-content`}`}
          >
            {open && (
              <Curriculum
                // courseDetail={courseContent?.course!}
                liveClass={live[0]}
                activeItem={selectedVideo!}
                lesson={selectedLesson!}
                setActiveItem={setSelectedVideo}
                videoType={videoType}
                videos={videos}
                pdfs={pdfs}
                cId={courseContent?.course?.id}
                setFeedBack={(feedBack) => setFeedBack(feedBack)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursePlayer;
