import { Button, Card, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import useQuizStore from "../../../store/quizStore";
import { useTimerStore } from "../../../store/timer";

const SectionSubmit = ({
  lastSectionId,
  scalingFactor,
  setPageId,
  setCurrentSectionTitle,
}: {
  lastSectionId: number;
  scalingFactor: number;
  setPageId: (value: number) => void;
  setCurrentSectionTitle: (data: string) => void;
}) => {
  const [isSubmitModal, setIsSubmitModal] = useState(false);

  const {resetTimer} = useTimerStore();

  const {
    quiz,
    activeTabId,
    setActiveTabId,
    status,
    currentSectionQues,
    setCurrentSectionQues,
  } = useQuizStore();

  const styles: any = {
    cards: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    cards_fonts: {
      fontSize: "2.5em",
      textAlign: "center",
      lineHeight: "0",
      marginTop: "20px",
    },
    cards_fonts_title: {
      width: "max-content",
      fontWeight: "bold",
    },
    allQuestions: {
      backgroundColor: "#EBECFF",
      border: "2px solid #2E3192",
      borderRadius: "10px",
      color: "#020887",
    },
    answered: {
      backgroundColor: "#27AE60",
      borderRadius: "10px",
      color: "#fff",
    },
    notAnswered: {
      backgroundColor: "#C0392B",
      borderRadius: "10px",
      color: "#fff",
    },
    reviewed: {
      backgroundColor: "#9B59B6",
      borderRadius: "10px",
      color: "#fff",
    },
    notVisited: {
      backgroundColor: "#fff",
      border: "2px solid #727272",
      borderRadius: "10px",
      color: "#474747",
    },
  };

  // Extract IDs from currentSectionQues
  const currentIds = currentSectionQues?.map((question) =>
    question.id.toString()
  );

  // Filter the status object
  const filteredStatus = Object.fromEntries(
    Object.entries(status)?.filter(([key]) => currentIds?.includes(key))
  );

  const visitedCount = Object.values(filteredStatus)?.filter(
    (item) => item.visited
  ).length;

  const bookmarkedCount = Object.values(filteredStatus)?.filter(
    (item) => item?.bookmark
  ).length;

  const attemptedCount = Object.values(filteredStatus)?.filter((item) => {
    if (Array.isArray(item.attempt)) {
      return item.attempt.length > 0;
    }
    return !!item.attempt;
  });

  return (
    <>
      {quiz?.meta?.is_section_timer && activeTabId !== lastSectionId && (
        <Button
          style={{
            width: "90%",
            fontWeight: "800",
            background: "#2E3192",
            color: "#fff",
            marginTop: "3%",
          }}
          onClick={() => {
            setIsSubmitModal(true);
          }}
          size={scalingFactor >= 1.5 ? "middle" : "large"}
        >
          Submit Section
        </Button>
      )}

      <Modal
        open={isSubmitModal}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsSubmitModal(false)}
        title={"Are you sure want to Submit Section"}
        footer={
          <Button
            style={{ background: "#2E3192", color: "#fff" }}
            onClick={() => {
              setActiveTabId(activeTabId + 1);
              setCurrentSectionQues(
                quiz?.sections?.find((i) => i?.id === activeTabId + 1)
                  ?.questions
              );
              setCurrentSectionTitle(
                quiz?.sections?.find((i) => i?.id === activeTabId + 1)?.title
              );
              setPageId(1);
              setIsSubmitModal(false);
              resetTimer();
            }}
            size={scalingFactor >= 1.5 ? "middle" : "large"}

          >
            Submit
          </Button>
        }
        width={800}
      >
        <Row
          gutter={[5, 5]}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.allQuestions }}>
              <p style={styles.cards_fonts_title}>All Questions</p>
              <p style={styles.cards_fonts}>{currentSectionQues?.length}</p>
            </Card>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.answered }}>
              <p style={styles.cards_fonts_title}>Answered</p>
              <p style={styles.cards_fonts}>{+attemptedCount?.length}</p>
            </Card>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.notAnswered }}>
              <p style={styles.cards_fonts_title}> Not Answered</p>
              <p style={styles.cards_fonts}>
                {currentSectionQues?.length - +attemptedCount?.length}
              </p>
            </Card>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.reviewed }}>
              <p style={styles.cards_fonts_title}>Bookmarked </p>
              <p style={styles.cards_fonts}>{bookmarkedCount}</p>
            </Card>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.notVisited }}>
              <p style={styles.cards_fonts_title}>Not Visited</p>
              <p style={styles.cards_fonts}>
                {currentSectionQues?.length - visitedCount}
              </p>
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SectionSubmit;
