import { Card, Col, Row, Badge, Tag } from "antd";
import React, { useEffect } from "react";
import { Link } from "@tanstack/react-router";
import { formatDate } from "../../helpers/help";
import styled from "styled-components";
import Shimmer from "../Shimmer";

import { liveClassesStore } from "../../store/liveClassesStore";

const LiveClasses = () => {
  const {liveStore,isLoading} = liveClassesStore((state:any)=>state)

  return (
    <Card
      style={{
        height: "100%",
        overflow: "auto",
      }}
      className="hideScrollbar"
    >
      <Heading>
        Live Classes <Badge count={liveStore.length} />
      </Heading>
      <Shimmer count={3} loading={liveStore===undefined}>
        {liveStore.length
          ? liveStore.slice(0, 4).map((item) => (
              <Link to={`/live/class?vvid=${item?.id}`}>
                <Row style={{ marginBottom: "5%" }}>
                  <Col lg={10} xl={10} md={9} sm={8} xs={8}>
                    <img
                      alt="video"
                      src={item?.thumb ? item?.thumb : "/kgs.jpg"}
                      width={"100%"}
                      style={{
                        borderRadius: "8px",
                        border: "0.5px solid #D5D5D5",
                      }}
                    />
                  </Col>
                  <Col lg={13} xl={13} md={14} sm={15} xs={15} offset={1}>
                    <h4
                      className="card-content"
                      style={{
                        marginBottom: "0",
                        color: "black",
                        fontSize: "0.9em",
                        fontWeight: "600",
                      }}
                    >
                      {item?.name}
                    </h4>
                    <SubTitle>
                      Live Classes started {formatDate(item?.published_at)}
                    </SubTitle>
                  </Col>
                </Row>
              </Link>
            ))
          : "Currently No Live Class Running"}
        <>
          {liveStore.length > 5 && (
            <Link to={"/live"}>
              <TagButton>See More</TagButton>
            </Link>
          )}
        </>
      </Shimmer>
    </Card>
  );
};

const Heading = styled.h3`
  font-weight: 500;
  font-size: 1.2em;
`;

export const SubTitle = styled.p`
  font-size: 0.7em;
  font-weight: 400;
  color: #000000;
`;

const TagButton = styled(Tag)`
  background-color: #f1f0ff;
  color: #4b4492;
  float: right;
  border: none;
  border-radius: 1em;
  padding: 0.2em 0.8em 0.2em 0.8em;
`;
export default LiveClasses;
