import { Card, Tag } from "antd";
import moment from "moment";
import React from "react";
import { Row, Col } from "antd";
import { Link } from "@tanstack/react-router";
import { TitleContainer, SubTitleContainer } from "./GlobalCard";
import styled from "styled-components";
import Live from "./icons/live";
import Recorded from "./icons/recorded";
import CustomCard from "./GlobalCard";

type IProps = {
  title: string;
  description?: string;
  published_at?: string;
  image: string;
  published?: string;
};

const CourseCard = ({
  title,
  image,
  published_at,
  description,
  published,
}: IProps) => {
  return (
    <>
      <CustomCard
        className={"card-active"}
        title={<TitleContainer>{title}</TitleContainer>}
        type="avatar"
        desc={
          <SubTitleContainer>
            <DateTimeDisplay dateTime={published} format="Do MMM YYYY" />
          </SubTitleContainer>
        }
        avatar={{ url: image }}
      />
    </>
  );

};

const DateTimeDisplay = ({ dateTime, format }) => {
  if (!dateTime) {
    return null;
  }

  return <span>{moment(dateTime).format(format)}</span>;
};
export default CourseCard;
