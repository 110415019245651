import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tag, Button, Input, Tooltip, Tabs } from "antd";
import { QuestionGraph } from "../QuestionGraph";
import useQuizStore, { isEqual } from "../../../store/quizStore";
import parse from "html-react-parser";
import { onCopy } from "../../../helpers/help";
import { IQuestion } from "../../../interfaces/quiz";
import styled from "styled-components";
import { LANG } from "../attempt/LanguageConvert";
import { Link, useRouter } from "@tanstack/react-router";
import { useMediaQuery } from "react-responsive";

const { TextArea } = Input;

type TOptionProps = {
  option: string;
  index: number;
  question: IQuestion;
};

interface IProps {
  currentSectionTitle?: string;
  setCurrentSectionTitle: (data: string) => void;
  pageId: number;
  setPageId: (val: number) => void;
}

const ReviewAndExplanation = ({
  currentSectionTitle,
  setCurrentSectionTitle,
  pageId,
  setPageId,
}: IProps) => {
  const router = useRouter();
  const [language, setLanguage] = useState(LANG.En);

  const {
    quiz,
    paginate,
    status,
    currentSectionQues,
    setActiveTabId,
    setCurrentSectionQues,
    activeTabId,
  } = useQuizStore();
  const { questions } = paginate(pageId);

  const getOptionColor = (question, index) => {
    const { attempt, answer } = status[question.id];
    if (`${answer}`.includes(`${index + 1}`)) return "#22c55e";
    if (!`${attempt}`.includes(`${index + 1}`)) return "";
    if (!isEqual(attempt, answer)) return "#ef4444";
  };

  const Option = ({ option, index, question }: TOptionProps) => {
    const backgroundColor = getOptionColor(question, index);
    if (!option) return;
    return (
      <OptionContainer key={index} $backgroundColor={backgroundColor}>
        <div
          style={{
            marginRight: "15px",
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
            fontWeight: "bold",
            padding: "5px 12px",
            borderRadius: "6px",
            cursor: "pointer",
            backgroundColor: backgroundColor ? backgroundColor : "#F5F6FF",
            color: backgroundColor ? "white" : "",
            border: "#d9d9d9",
          }}
        >
          {String.fromCharCode(65 + index)}
        </div>
        <div
          style={{
            background: backgroundColor ? backgroundColor : "#F5F6FF",
            display: "flex",
            padding: "5px 12px",
            borderRadius: "10px",
            cursor: "pointer",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="quiz-options">{parse(String(option))}</div>
        </div>
      </OptionContainer>
    );
  };

  const handleNavigation = (position: number) => {
    setPageId(position);
    const element = document.getElementById("explanation");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCurrentSection = (id: number, index: number) => {
    const currSecQues = quiz?.sections?.find(
      (item) => item?.id === id
    )?.questions;
    const title = quiz?.sections?.find((item) => item?.id === id)?.title;
    setCurrentSectionTitle(title);
    setCurrentSectionQues(currSecQues);
    handleNavigation(index);
    setActiveTabId(id);
  };

  const getBackgroundColor = (questionId: any) => {
    const questionStatus = status[questionId];
    if (questionStatus.attempt.length > 0) {
      if (Number(questionStatus.attempt) === Number(questionStatus.answer)) {
        return "#22c55e";
      } else {
        return "#ef4444";
      }
    }
    return "#fff";
  };

  const getColor = (questionId: any) => {
    const questionStatus = status[questionId];
    if (questionStatus.attempt.length > 0) {
      if (Number(questionStatus.attempt) === Number(questionStatus.answer)) {
        return "#fff";
      } else {
        return "#fff";
      }
    }
    return "black";
  };

  const isMobile = useMediaQuery({
    query: "(max-width: 991px)",
  });

  useEffect(() => {
    if (quiz) {
      setCurrentSectionQues(quiz?.sections?.map((item) => item)[0]?.questions);
      setCurrentSectionTitle(quiz?.sections?.map((item) => item)[0]?.title);
    }
  }, [quiz]);

  const firstSectionId = quiz?.sections?.map((i) => i)[0]?.id;
  const lastSectionId = quiz?.sections?.map((i) => i)?.slice(-1)[0]?.id;

  return (
    <SubContainer>
      <Row
        style={{
          height: "8%",
          background: "#EAF0FF",
          color: "#2E3192",
          fontSize: "1.2em",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          paddingLeft: "1.5em",
        }}
      >
        Review & Explanation
      </Row>
      <Row style={{ height: "92%", display: "flex", flexWrap: "nowrap" }}>
        <Col
          xxl={18}
          xl={18}
          lg={18}
          md={24}
          sm={24}
          xs={24}
          style={{ flex: "1" }}
        >
          <Row
            style={{ height: "10%" }}
            align={"middle"}
            justify={"space-between"}
          >
            <Row>
              {currentSectionTitle && (
                <ButtonTag>{currentSectionTitle}</ButtonTag>
              )}
              {!isMobile && (
                <Row>
                  <ButtonTag>{"Question " + pageId}</ButtonTag>
                </Row>
              )}
            </Row>

            {/* Section Header */}
            {!isMobile && (
              <Row>
                <LangButton
                  onClick={() => setLanguage(LANG.En)}
                  $active={language === LANG.En}
                >
                  English
                </LangButton>

                <LangButton
                  onClick={() => setLanguage(LANG.Hi)}
                  $active={language === LANG.Hi}
                >
                  Hindi
                </LangButton>
              </Row>
            )}
          </Row>

          {/* Question Index Header */}

          {isMobile && (
            <Row
              style={{ height: "10%" }}
              align={"middle"}
              justify={"space-between"}
            >
              <Row>
                <ButtonTag>{"Question " + pageId}</ButtonTag>
              </Row>
              <Row>
                <LangButton
                  onClick={() => setLanguage(LANG.En)}
                  $active={language === LANG.En}
                >
                  English
                </LangButton>

                <LangButton
                  onClick={() => setLanguage(LANG.Hi)}
                  $active={language === LANG.Hi}
                >
                  Hindi
                </LangButton>
              </Row>
            </Row>
          )}

          {/* Quiz Body */}
          <Row
            style={{
              height: !isMobile ? "80%" : "70%",
              overflowY: "scroll",
            }}
          >
            {questions?.map((question) => {
              return (
                <>
                  <Row style={{ width: "100%" }}>
                    <Col span={20}>
                      <p onCopy={onCopy} className="review-questions">
                        {parse(
                          language === LANG.Hi ? question?.hi.q : question?.q
                        )}
                      </p>

                      {question.type === 5 ? (
                        <TextArea
                          rows={5}
                          value={status[question.id].attempt}
                          disabled={true}
                        />
                      ) : question.type === 4 ? (
                        <>
                          <span
                            style={{
                              float: "right",
                              fontSize: "1.3em",
                              marginBottom: "1%",
                              color: "#22c55e",
                              fontWeight: "600",
                            }}
                          >
                            Correct Answer: {status[question.id].answer}
                          </span>
                          <Input
                            size="large"
                            disabled={true}
                            value={status[question.id].attempt}
                            style={{
                              background: getBackgroundColor(question.id),
                              color: getColor(question.id),
                              fontWeight: "bold",
                            }}
                          />
                        </>
                      ) : (
                        <Row align="middle">
                          <Col xl={15} lg={18} md={21} sm={22} xs={22}>
                            {language === LANG.En ? (
                              <>
                                {(question?.options || [])?.map(
                                  (option, index) => (
                                    <Option
                                      question={question}
                                      option={option}
                                      index={index}
                                    />
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                {(question?.hi?.options || [])?.map(
                                  (option, index) => (
                                    <Option
                                      question={question}
                                      option={option}
                                      index={index}
                                    />
                                  )
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>

                  <Row
                    style={{
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <Col xl={20} lg={20} md={21} sm={22} xs={22}>
                      <div
                        style={{ fontSize: "1.2em" }}
                        // className="quiz-questions"
                      >
                        <p
                          style={{
                            margin: "0%",
                            fontWeight: "bold",
                            fontSize: "1.2em",
                          }}
                        >
                          Explanation:
                        </p>
                        <p className="review-questions">
                          {question.note === null
                            ? "Explanation not available!"
                            : parse(
                                language === LANG.Hi
                                  ? String(question?.hi_note)
                                  : String(question?.note)
                              )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </>
              );
            })}
          </Row>

          {/* footer of the Review and explanation */}
          <Row
            style={{ height: "10%", paddingRight: "0.5em" }}
            align={"middle"}
            justify={"space-between"}
          >
            {pageId > 1 && (
              <Button
                onClick={() => setPageId((pageId) => pageId - 1)}
                style={{
                  fontWeight: 800,
                  paddingLeft: "1.2em",
                  paddingRight: "1.2em",
                }}
              >
                Previous
              </Button>
            )}

            {firstSectionId !== activeTabId &&
              currentSectionQues?.[0]?.id === questions?.[0]?.id && (
                <Button
                  style={{
                    fontWeight: 800,
                    paddingLeft: "1.2em",
                    paddingRight: "1.2em",
                  }}
                  onClick={() => {
                    setActiveTabId(activeTabId - 1);
                    setCurrentSectionQues(
                      quiz?.sections?.find((i) => i?.id === activeTabId - 1)
                        ?.questions
                    );
                    setCurrentSectionTitle(
                      quiz?.sections?.find((i) => i?.id === activeTabId - 1)
                        ?.title
                    );
                    setPageId(1);
                  }}
                >
                  Previous
                </Button>
              )}
            {pageId < (quiz?.questions || currentSectionQues)?.length! && (
              <Button
                onClick={() => setPageId(pageId + 1)}
                style={{
                  fontWeight: 800,
                  background: "#2E3192",
                  color: "#fff",
                  paddingLeft: "1.2em",
                  paddingRight: "1.2em",
                }}
              >
                Next
              </Button>
            )}
            {lastSectionId !== activeTabId &&
              currentSectionQues?.[currentSectionQues?.length - 1]?.id ===
                questions?.[0]?.id && (
                <Button
                  onClick={() => {
                    setActiveTabId(activeTabId + 1);
                    setCurrentSectionQues(
                      quiz?.sections?.find((i) => i?.id === activeTabId + 1)
                        ?.questions
                    );
                    setCurrentSectionTitle(
                      quiz?.sections?.find((i) => i?.id === activeTabId + 1)
                        ?.title
                    );
                    setPageId(1);
                  }}
                  style={{
                    fontWeight: 800,
                    background: "#2E3192",
                    paddingLeft: "1.2em",
                    paddingRight: "1.2em",
                  }}
                >
                  Next
                </Button>
              )}
          </Row>
        </Col>
        <Col
          xxl={6}
          xl={6}
          lg={6}
          md={0}
          sm={0}
          xs={0}
          style={{
            background: "yellow",
            width: "31em",
            maxWidth: "31em",
            minWidth: "31em",
            flex: "0 0 auto",
          }}
        >
          <QuestionGraph
            handleCurrentSection={handleCurrentSection}
            navigate={handleNavigation}
          />
        </Col>
      </Row>
    </SubContainer>
  );
};

const Container = styled.div`
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0.5em;
`;

const SubContainer = styled.div`
  // overflow-y: scroll;
  height: 77vh;
`;

const Title = styled.p`
  font-weight: 600;
  background: #eaf0ff;
  padding: 1% 1% 1% 2%;
  border-radius: 0.3em 0.3em 0em 0em;
  margin-bottom: 0%;
  font-size: 1.2em;
`;
export default ReviewAndExplanation;

const LangButton = styled(Tag)<{ $active?: boolean }>`
  padding: 5px 15px;
  border-radius: 30px;
  cursor: pointer;
  background-color: ${(props) => (props.$active ? "#F5F6FF" : "")};
  border-color: ${(props) => (props.$active ? "#4A3AFF" : "")};
  color: ${(props) => (props.$active ? "#4A3AFF" : "")};
`;

const OptionContainer = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  color: ${(props) => (props.$backgroundColor ? "white" : "")};
`;

const ButtonTag = styled(Tag)`
  background: #f5f6ff;
  color: #1e1b39;
  border: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.2em;
`;
